import React from 'react';
import styled from 'styled-components';
import Youtube from 'react-youtube';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../Modal';
import { SCREEN_SIZE } from '../../../style';
import { CONFIG } from '../../../config'

const StyledYoutubeModal = styled(Modal)`
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  min-width: 256px;
  min-height: 360px;
  ${SCREEN_SIZE.LG} {
    min-width: 640px;
  }
`;

const StyledYoutube = styled(Youtube)`
  max-width: 100%;
`;

const YoutubeModal = ({
  onCloseClick,
  videoId,
}) => (
  <StyledYoutubeModal
    onCloseClick={onCloseClick}
  >
    <StyledYoutube
      videoId={videoId}
      opts={{
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      }}
    />
  </StyledYoutubeModal>
);

YoutubeModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
};

const EnhancedYoutubeModel = compose(
  connect(({ modal }) => ({ videoId: modal.data.videoId || CONFIG.USERS_VIDEO_ID })),
)(YoutubeModal);

export { EnhancedYoutubeModel as YoutubeModal };