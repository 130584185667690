const STYLES = {
  COLOR_PRIMARY: '#ff6363',
  COLOR_BLACK: '#000',
  COLOR_WHITE: '#fff',
  COLOR_SANDAL_BROWN: '#B2906E',
  COLOR_OLIVINE_GREEN: '#94B56F',
  COLOR_MANTIS_GREEN: '#3ED959',
  COLOR_APPLE_GREEN: '#63BE43',
  COLOR_BITTER_SWEET_RED: '#ff6363',
  COLOR_JUNGLE_MIST_GREEN: '#A4CCCE',
  COLOR_MING_GREEN: '#398287',
  COLOR_SHAKESPEARE_BLUE: '#44A5CA',
  COLOR_CORN_FLOWER_BLUE: '#8BCFE1',
  COLOR_PABLO_BROWN: '#7E7463',
  COLOR_BOULDER_GRAY: '#767676',
  COLOR_DOVE_GRAY: '#696969',
  COLOR_SILVER_GRAY: '#ccc',
  COLOR_SEASHELL_GRAY: '#f1f1f1',
  COLOR_HAVELOCK_BLUE: '#4A90E2',

  PAGE_FAQ_BANNER_COLOR: '#F2A3C9',
  PAGE_LEGAL_BANNER_COLOR: '#D3AB8F',
  PAGE_HOME_BANNER_COLOR: '#0ABAB5',
  PAGE_VISION_BANNER_COLOR: '#AAD7D9',
  PAGE_SERVICES_BANNER_COLOR: '#FFA07B',
  PAGE_INVESTORS_BANNER_COLOR: '#8BCFE1',
  PAGE_HOW_IT_WORKS_BANNER_COLOR: '#D7A4A4',
  PAGE_COMMUTERS_BANNER_COLOR: '#77B9ED',
  PAGE_BUSINESSES_BANNER_COLOR: '#A5E78E',
  PAGE_BUSINESSES_HOW_TO_BANNER_COLOR: '#EDC1EC',
  PAGE_BUSINESSES_SECURITY_BANNER_COLOR: '#FF6363',
  PAGE_BUSINESSES_SUPPORT_BANNER_COLOR: '#8CD4DD',
  PAGE_BUSINESSES_SAVINGS_BANNER_COLOR: '#BBE3B3',

  BORDER_RADIUS: '.5rem',
  BORDER_COLOR: '#D2D2D2',

  TEXT_COLOR: '#333',
  LINK_COLOR: '#333',
  LINK_HOVER_COLOR: '#ff6363',
  HOVER_COLOR: '#f3f3f3',
  FONT_FAMILY: '"Quicksand", sans-serif',

  GUTTER: '.5rem',

  MAX_CONTENT_WIDTH: '64rem',

  LINE_HEIGHT_MD: '1.5rem', // 24px
  LINE_HEIGHT_LG: '2rem', // 32px
  LINE_HEIGHT_XLG: '2.375rem', // 38px

  SECTION_GRAY_BACKGROUND_COLOR: '#F0F0F0',

  FONT_WEIGHT_NORMAL: 200,
  FONT_WEIGHT_HEAVY: 400,
  FONT_WEIGHT_BOLD: 700,

  FONT_SIZE_XXXXLG: '2.5rem', // 40px
  FONT_SIZE_XXXLG: '2rem', // 32px
  FONT_SIZE_XXLG: '1.75rem', // 28px
  FONT_SIZE_XXLG_LINE_HEIGHT: '2.375rem',
  FONT_SIZE_XLG: '1.5rem', // 24px
  FONT_SIZE_LG: '1.3rem', // 21px
  FONT_SIZE_MD: '1.2rem', // 20px
  FONT_SIZE_LSM: '1.1rem',
  FONT_SIZE_SM: '1rem', // 16px
  FONT_SIZE_XS: '.875rem',
};

const BREAKPOINT = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

const SCREEN_SIZE = {
  XS: `@media screen and (min-width: ${BREAKPOINT.XS}px)`,
  SM: `@media screen and (min-width: ${BREAKPOINT.SM}px)`,
  MD: `@media screen and (min-width: ${BREAKPOINT.MD}px)`,
  LG: `@media screen and (min-width: ${BREAKPOINT.LG}px)`,
  XL: `@media screen and (min-width: ${BREAKPOINT.XL}px)`,
};

export { STYLES, BREAKPOINT, SCREEN_SIZE };