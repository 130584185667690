import React from 'react';
import styled from 'styled-components';
import FaAngleUp from 'react-icons/lib/fa/angle-up';
import { compose, withHandlers, withState } from 'recompose';
import PropTypes from 'prop-types';
import { STYLES } from '../../../style';
import { ScrollHelper } from '../../../helpers/ScrollHelper';
import { withScroll } from '../hoc/withScroll';

const StyledScrollUpButton = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border-radius: 3rem;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
/*
  max-width: 2rem;
  max-height: 2rem;
*/
  width: 3rem;
  height: 3rem;
  font-size: 38px;
  color: #666;
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
  display: flex;
  right: -1000px;
  ${({ show }) => show && `
    opacity: 0.8;
    right: 2rem;
  `}
  .no-touch &:hover {
    background-color: ${STYLES.COLOR_PRIMARY};
    color: ${STYLES.COLOR_WHITE};
/*
    max-width: 3rem;
    max-height: 3rem;
*/
  }
`;

const StyledFaAngleUp = styled.div`
  transform: translateY(-5px);
`;

const ScrollUpButton = ({
  scrollUp, isShowButton,
}) => (
  <StyledScrollUpButton onClick={scrollUp} show={isShowButton}>
    <StyledFaAngleUp>
      <FaAngleUp />
    </StyledFaAngleUp>
  </StyledScrollUpButton>
);

ScrollUpButton.defaultProps = {
  isShowButton: false,
};

ScrollUpButton.propTypes = {
  scrollUp: PropTypes.func.isRequired,
  isShowButton: PropTypes.bool,
};

const EnhancedScrollUpButton = compose(
  withState('isShowButton', 'setIsShowButton'),
  withScroll({
    callback: (ev, props) => {
      const { setIsShowButton } = props;
      setIsShowButton(window.scrollY > 200);
    },
  }),
  withHandlers({
    scrollUp: () => () => {
      ScrollHelper.scrollTo(0);
    },
  }),
)(ScrollUpButton);

export { EnhancedScrollUpButton as ScrollUpButton };