import styled from 'styled-components';
import { STYLES } from '../../../style';

const Table = styled.table.attrs({
  cellSpacing: '0',
  cellPadding: '0',
})`
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
`;

const TableRow = styled.tr`
`;

const TableHeader = styled.th`
  padding: 2rem 0;
  border-bottom: 1px solid ${STYLES.COLOR_SILVER_GRAY};
  text-align: ${({ left }) => (left ? 'left' : 'center')};
`;

const TableCell = styled.td`
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  padding: 2rem 0;
  border-bottom: 1px solid ${STYLES.COLOR_SILVER_GRAY};
  ${({ bold }) => bold && `
    font-weight: 600;
  `}
`;

export {
  Table, TableRow, TableCell, TableHeader,
};