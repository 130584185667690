import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withState } from 'recompose';
import SmallLogoBorderImage from '../../../images/logo-small-border.png';
import WordMarkImage from '../../../images/wordmark.png';
import WordMarkWhiteImage from '../../../images/wordmark-white.png';
import WordMarkRedImage from '../../../images/wordmark-red.png';
import { Link } from '../Link';
import { SCREEN_SIZE } from '../../../style';

const HEADER_LOGO_THEME = {
  WHITE: 'WHITE',
  DEFAULT: 'DEFAULT',
};

const SmallLogo = styled.img.attrs({
  alt: 'Handcarry logo',
})`
  width: 2.5rem;
  height: 2.5rem;
`;

const WordMark = styled.img.attrs({
  alt: 'Hand Carry wordmark',
  src: WordMarkImage,
})`
  height: 2.4rem;
  margin-left: .5rem;
  display: ${({ show }) => (show ? 'block' : 'none')}
`;

const WordMarkRed = styled.img.attrs({
  alt: 'Hand Carry wordmark',
  src: WordMarkRedImage,
})`
  height: 2.4rem;
  margin-left: .5rem;
  display: ${({ show }) => (show ? 'block' : 'none')}
`;

const WordMarkWhite = styled.img.attrs({
  alt: 'Hand Carry wordmark',
  src: WordMarkWhiteImage,
})`
  height: 2.4rem;
  margin-left: .5rem;
`;

const StyledHeaderLogo = styled(Link)`
  padding: .5rem .5rem .5rem 0;
  display: flex;
  align-items: center;
  ${SCREEN_SIZE.LG} {
    padding: .5rem;
  }
`;


const HeaderLogo = ({
  theme,
  isHover,
  setIsHover,
}) => (
  <StyledHeaderLogo
    to="/"
    onMouseOver={() => {
      if (!('ontouchstart' in document.documentElement)) {
        setIsHover(true);
      }
    }}
    onMouseLeave={() => {
      if (!('ontouchstart' in document.documentElement)) {
        setIsHover(false);
      }
    }}
  >
    <SmallLogo src={SmallLogoBorderImage} />
    {
      theme === HEADER_LOGO_THEME.DEFAULT
        ? (
          <>
            <WordMark show={!isHover} />
            <WordMarkRed show={isHover} />
          </>
        ) : <WordMarkWhite />
    }
  </StyledHeaderLogo>
);

HeaderLogo.defaultProps = {
  theme: HEADER_LOGO_THEME.DEFAULT,
};

HeaderLogo.propTypes = {
  theme: PropTypes.oneOf(Object.values(HEADER_LOGO_THEME)),
  isHover: PropTypes.bool.isRequired,
  setIsHover: PropTypes.func.isRequired,
};

const EnhancedHeaderLogo = compose(
  withState('isHover', 'setIsHover', false),
)(HeaderLogo);

export { HEADER_LOGO_THEME, EnhancedHeaderLogo as HeaderLogo };