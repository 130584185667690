import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from '../Link';
import { STYLES } from '../../../style';

const StyledHeaderLink = styled(Link)`
  color: ${({ color }) => color};
  padding: 1rem 0;
  margin: 0 1rem;
  font-size: ${STYLES.FONT_SIZE_SM};
  text-decoration: none;
  display: flex;
  align-items: center;
  ${({ active, activeColor }) => active && `
    border-bottom: 3px solid ${activeColor};
    color: ${activeColor}
  `}
  .no-touch &:hover {
    color: ${({ hoverColor }) => hoverColor};
    opacity: 0.7;
    text-decoration: none;
  }
`;

const HeaderLink = ({
  url,
  color,
  hoverColor,
  activeColor,
  children,
  active,
}) => (
  <StyledHeaderLink
    to={url}
    hoverColor={hoverColor}
    color={color}
    active={active}
    activeColor={activeColor}
  >
    {children}
  </StyledHeaderLink>
);

HeaderLink.defaultProps = {
  active: false,
};

HeaderLink.propTypes = {
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string.isRequired,
  activeColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
};

export { HeaderLink };