import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withState } from 'recompose';
import { HEADER_LOGO_THEME, HeaderLogo } from './HeaderLogo';
import { HeaderLinks } from './HeaderLinks';
import { SCREEN_SIZE, STYLES } from '../../../style'
import { Centralized } from '../layout/Centralized';
import { MobileTopNav } from '../MobileTopNav';

const HEADER_LINKS = [
  { url: '/vision', text: 'Vision' },
  { url: '/services', text: 'Services' },
  { url: '/how-it-works', text: 'How it works' },
  { url: '/businesses', text: 'Businesses', activeRe: /^\/businesses/ },
  { url: '/commuters', text: 'Commuters' },
  { url: '/investors', text: 'Investors' },
];

const StyledHeader = styled.div`
  left: 0;
  top: 0;
  right: 0;
  padding: 1rem 1rem 0 0;
  transition-duration: .3s;
  transition-property: transform,opacity;
  ${({ transparent }) => transparent && `
    position: absolute;
  `}
  ${SCREEN_SIZE.LG} {
    padding: 1rem 1rem 0 1rem;
  }
  ${({ freezed, showFreezed, isMenuOpen }) => freezed && `
    opacity: ${showFreezed ? 1 : 0};
/*
    transform: ${isMenuOpen ? 'initial' : showFreezed ? 'translateY(0)' : 'translateY(-75px)'};
*/
    padding: 0;
    position: fixed;
    background-color: ${STYLES.COLOR_WHITE};
    box-shadow: 0 0 7px #ddd;
    ${isMenuOpen ? '' : showFreezed ? 'z-index: 10' : 'z-index: -1'};
    ${SCREEN_SIZE.LG} {
      padding: 0 1rem;
    }
  `}
  ${({ isMenuOpen, headerY }) => isMenuOpen && `
    position: absolute;
    top: ${headerY}px;
  `}
`;

const HeaderContent = styled(Centralized)`
  padding: 0 1rem 0 0;
  display: flex;
  ${SCREEN_SIZE.LG} {
    padding: 0;
  }
`;

const StyledMobileTopNav = styled(MobileTopNav)`
`;

const Header = ({
  pathname, transparent, freezed, showFreezedHeader, headerY, isMenuOpen, setIsMenuOpen,
}) => {
  const isTransparent = transparent && !freezed;
  return (
    <StyledHeader
      transparent={transparent}
      freezed={freezed}
      showFreezed={showFreezedHeader}
      headerY={headerY}
      isMenuOpen={isMenuOpen}
    >
      <HeaderContent>
        <StyledMobileTopNav
          transparent={transparent}
          links={HEADER_LINKS}
          pathname={pathname}
          isOpen={isMenuOpen}
          onOpenChange={isOpen => setIsMenuOpen(isOpen)}
        />
        <HeaderLogo theme={isTransparent ? HEADER_LOGO_THEME.WHITE : HEADER_LOGO_THEME.DEFAULT} />
        <HeaderLinks
          links={HEADER_LINKS}
          linkColor={isTransparent ? STYLES.COLOR_WHITE : STYLES.LINK_COLOR}
          linkHoverColor={isTransparent ? STYLES.COLOR_WHITE : STYLES.COLOR_PRIMARY}
          linkActiveColor={isTransparent ? STYLES.COLOR_WHITE : STYLES.COLOR_PRIMARY}
          pathname={pathname}
        />
      </HeaderContent>
    </StyledHeader>
  );
};

Header.defaultProps = {
  freezed: false,
  showFreezedHeader: false,
  transparent: false,
  headerY: null,
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
  transparent: PropTypes.bool,
  freezed: PropTypes.bool,
  showFreezedHeader: PropTypes.bool,
  headerY: PropTypes.number,
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
};

const EnhancedHeader = compose(
  withState('isMenuOpen', 'setIsMenuOpen', false),
)(Header);

export { EnhancedHeader as Header };
