import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { YoutubeModal } from '../YoutubeModal';
import { SubscriptionModal } from '../SubscriptionModal';
import { hideModal } from '../../../states/modal/actions';
import { MODAL_TYPE } from './constants';

const ModalManager = ({
  modal,
  dispatch,
}) => {
  switch (modal.name) {
    case MODAL_TYPE.YOUTUBE_MODAL:
      return <YoutubeModal onCloseClick={() => dispatch(hideModal())} />;
    case MODAL_TYPE.SUBSCRIPTION_MODAL:
      return <SubscriptionModal onCloseClick={() => dispatch(hideModal())} />;
    default:
      return null;
  }
};

ModalManager.propTypes = {
  // eslint-disable-next-line
  modal: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const EnhancedModalManager = compose(
  connect(({ modal }) => ({ modal })),
)(ModalManager);

export { EnhancedModalManager as ModalManager };