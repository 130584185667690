const SHIPMENT_STATUS = {
  MERCHANT_SUBMITTED: 'MERCHANT_SUBMITTED',
  RECIPIENT_CONFIRMED: 'RECIPIENT_CONFIRMED',
  COMMUTER_ACCEPTED: 'COMMUTER_ACCEPTED',
  COMMUTER_PICKED_UP: 'COMMUTER_PICKED_UP',
  MERCHANT_CANCELLED: 'MERCHANT_CANCELLED',
  DELIVERED: 'DELIVERED',
};

export { SHIPMENT_STATUS };
