import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';

const withScroll = ({ callback }) => Component => {
  const scrollCallback = function(props, ev) {
    callback(ev, props);
  };
  return compose(
    lifecycle({
      componentDidMount() {
        window.addEventListener('scroll', scrollCallback.bind(this, this.props));
      },
      componentWillUnmount() {
        window.removeEventListener('scroll', scrollCallback.bind(this, this.props));
      },
    }),
  )(Component);
};

withScroll.propTypes = {
  callback: PropTypes.func.isRequired,
};

export { withScroll };
