import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { compose, lifecycle } from 'recompose';
import qs from 'qs';
import { connect } from 'react-redux';
import FaCalendarO from 'react-icons/lib/fa/calendar-o';
import MdAccessTime from 'react-icons/lib/md/access-time';
import TiHomeOutline from 'react-icons/lib/ti/home-outline';
import MdStore from 'react-icons/lib/md/store';
import moment from 'moment';
import { times } from 'ramda';
import LockerIconImage from '../images/locker.png';
import { Layout, Centralized } from '../components/shared/layout';
import {
  Button, Dropdown, HeaderText, Page, TextArea,
} from '../components/shared';
import { recipientConfirmDelivery, getDeliveryByUuid } from '../states/deliveries/actions';
import { DeliveryProp } from '../props';
import { FileHelper } from '../helpers/FileHelper';
import { withFormFields } from '../components/shared/hoc/withFormFields';
import { ShipmentHelper } from '../helpers/ShipmentHelper';
import { SHIPMENT_STATUS } from '../constants/ShipmentStatus'
import { STYLES } from '../style'

const StyledConfirmDelivery = styled(Layout)`
`;

const ProductDetails = styled.div`
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 3px;
`;

const date = moment().subtract('day', 1);
const dayItems = times(() => {
  date.add('day', 1);
  return ({ text: date.format('DD MMM, YYYY'), value: date.format('DD-MMM-YYYY') });
}, 7);
const timeItems = [
  '12am - 3am',
  '3am - 6pm',
  '6am - 9pm',
  '9am - 12pm',
  '12pm - 3pm',
  '3pm - 6pm',
  '6pm - 9pm',
  '9pm - 12am',
].map(item => ({ text: item, value: item }));

const LockerIcon = styled.img.attrs({
  src: LockerIconImage,
})``;

const createLocationItems = address => [
  { text: address, icon: TiHomeOutline },
  { text: 'Select a Pudo station', icon: LockerIcon },
  { text: 'Select a Kobini location', icon: MdStore },
].map(item => ({ text: item.text, value: item.text, icon: item.icon }));

const SorryMessage = styled.div`
  text-align: center;
  font-size: ${STYLES.FONT_SIZE_LG};
  padding: 10rem 0;
`;

const ConfirmDelivery = ({
  delivery,
  formFields,
  setFormField,
  dispatch,
}) => {
  if (!delivery) {
    return null;
  }
  const hasConfirmed = delivery.status !== SHIPMENT_STATUS.MERCHANT_SUBMITTED;
  const { to: deliveryTo } = delivery;
  const { from: deliveryFrom } = delivery;
  const { imageUrls } = delivery;
  return (
    <StyledConfirmDelivery>
      <Page>
        <Centralized>
          { !hasConfirmed ? (
            <>
              <HeaderText priority={2}>
                Hi {deliveryTo.firstName}, {deliveryFrom.name} is sending you a parcel
              </HeaderText>
              <ProductDetails>
                {(imageUrls || []).map(imageKey => {
                  const thumbnailImageUrl = FileHelper.getFileUrl(imageKey);
                  return (
                    <ProductImage key={thumbnailImageUrl} src={thumbnailImageUrl} />
                  );
                })}
              </ProductDetails>
              <p>Please help us get that parcel to you as soon as possible.</p>
              <p>When would you like to receive your delivery?</p>
              <div>
                <Dropdown
                  defaultText="-- Select a date --"
                  items={dayItems}
                  value={formFields.idealDeliveryDate}
                  icon={<FaCalendarO />}
                  onItemClick={item => setFormField('idealDeliveryDate', item.value)}
                />
              </div>
              <div>
                <Dropdown
                  defaultText="-- Select a time --"
                  items={timeItems}
                  value={formFields.idealDeliveryTime}
                  icon={<MdAccessTime />}
                  onItemClick={item => setFormField('idealDeliveryTime', item.value)}
                />
              </div>
              <p>How would you like to receive your parcel?</p>
              <div>
                <Dropdown
                  defaultText="-- Select a location --"
                  items={createLocationItems(ShipmentHelper.getToFullAddress(delivery))}
                  value={formFields.deliveryTo}
                  icon={<TiHomeOutline />}
                  onItemClick={item => setFormField('deliveryTo', item.value)}
                />
              </div>
              <p>Note</p>
              <div>
            <TextArea
              placeholder="Hey when you arrive, my door is the blue door, don't worry about my dog, just buzz unit 1"
              value={formFields.deliveryNote}
              onChange={ev => setFormField('deliveryNote', ev.target.value)}
            />
              </div>
              <div>
                <Button
                  onClick={() => {
                    dispatch(recipientConfirmDelivery(delivery.uuid, {
                      data: formFields,
                      resolve: () => navigate('/delivery-confirmed'),
                    }));
                  }}
                  disabled={
                    !(formFields.idealDeliveryDate && formFields.idealDeliveryTime && formFields.deliveryTo)
                  }
                >
                  Confirm
                </Button>
              </div>
            </>
          ) : <SorryMessage>Sorry, you have confirm your delivery earlier.</SorryMessage>}
        </Centralized>
      </Page>
    </StyledConfirmDelivery>
  );
};

ConfirmDelivery.defaultProps = {
  delivery: null,
};

ConfirmDelivery.propTypes = {
  delivery: PropTypes.shape(DeliveryProp),
  // eslint-disable-next-line
  formFields: PropTypes.object.isRequired,
  setFormField: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const EnhancedConfirmDelivery = compose(
  withFormFields({
    initialValues: () => ({
      idealDeliveryDate: null,
      idealDeliveryTime: null,
      deliveryTo: null,
      deliveryNote: '',
    }),
  }),
  connect(
    ({ deliveries }, { location: { search } }) => {
      const { id: uuid } = qs.parse(search, { ignoreQueryPrefix: true });
      return ({ delivery: deliveries.list.find(item => item.uuid === uuid) });
    },
  ),
  lifecycle({
    componentDidMount() {
      const { dispatch, location: { search } } = this.props;
      const { id: uuid } = qs.parse(search, { ignoreQueryPrefix: true });
      if (uuid) {
        dispatch(getDeliveryByUuid(uuid));
      }
    },
  }),
)(ConfirmDelivery);

export default EnhancedConfirmDelivery;