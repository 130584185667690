import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GLink } from 'gatsby';

const StyledLink = styled(GLink)`
`;

const Link = ({ to, children, className, onMouseOver, onMouseLeave }) => {
  if (to && /^\//.test(to)) {
    return (
      <StyledLink
        to={to}
        className={className}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </StyledLink>
    );
  }
  if (to && /^mailto/.test(to)) {
    return (
      <a href={to} className={className}>{children}</a>
    );
  }
  return (
    <a href={to || 'javascript: void(0)'}
       target="_blank"
       className={className}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  className: null,
  onMouseLeave: null,
  onMouseOver: null,
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export { Link };