import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DropdownItemProp } from './DropdownItemProp';
import { STYLES } from '../../../style';
import { Text } from '../Text';

const StyledDropdownMenuItem = styled.div`
  padding: 1rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  .no-touch &:hover {
    background-color: ${STYLES.HOVER_COLOR};
  }
  &:hover {
    background-color: ${STYLES.HOVER_COLOR};
  }
`;

const StyledText = styled(Text)`
  padding-left: 1rem;
`;

const DropdownMenuItem = ({ item, onItemClick }) => {
  let StyledItemIcon;
  if (item.icon) {
    StyledItemIcon = styled(item.icon)`
      width: 18px;
      max-height: 18px;
    `;
  }
  return (
    <StyledDropdownMenuItem
      onClick={() => onItemClick(item) }
    >
      { StyledItemIcon && <StyledItemIcon /> }
      <StyledText>{item.text}</StyledText>
    </StyledDropdownMenuItem>
  );
};

DropdownMenuItem.propTypes = {
  item: PropTypes.shape(DropdownItemProp).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export { DropdownMenuItem };