import React from 'react';
import styled from 'styled-components';
import { withHandlers, compose, withState } from 'recompose';
import PropTypes from 'prop-types';
import { SCREEN_SIZE, STYLES } from '../../../style';
import { TextBox } from '../TextBox';
import { Button } from '../Button';

const SubscriptionFormFields = styled.div`
  padding: 1rem 0;
  ${SCREEN_SIZE.MD} {
    display: flex;
    align-items: center;
  }
`;

const EmailField = styled(TextBox)`
  input {
    border-radius: 1.5rem;
    border-width: 0;
    
    ${SCREEN_SIZE.MD} {
      min-width: 16rem;
    }
  }
`;

const StyledSubscriptionForm = styled.form`
`;

const StatusMessage = styled.div`
  padding: 0 0 1rem;
  color: green;
`;

const ErrorStatusMessage = styled(StatusMessage)`
  color: red;
`;

const GetUpdatesButton = styled(Button).attrs({
  children: 'Subscribe',
})`
  &:hover {
    opacity: 0.9;
  }
  ${SCREEN_SIZE.MD} {
    margin: 1rem 0 1rem 1rem;
  }
`;

const ModalTitle = styled.div`
  font-size: ${STYLES.FONT_SIZE_XXLG};
  margin: 1.2rem 0 2rem;
`;

const ModalDescription = styled.div`
  font-size: ${STYLES.FONT_SIZE_LG};
  line-height: ${STYLES.LINE_HEIGHT_XLG};
`;

const SubscriptionForm = ({
  status,
  message,
  onSubmit,
  email,
  setEmail,
}) => {
  if (status === 'success') {
    return (
      <div>
        <ModalTitle>
          Thank you for subscribing!
        </ModalTitle>
        <ModalDescription
          style={{ marginBottom: '1.2rem'}}
        >
          Please verify your email address to confirm you wish to receive updates.
        </ModalDescription>
      </div>
    );
  }
  return (
    <StyledSubscriptionForm onSubmit={onSubmit}>
      <ModalTitle>
        Subscribe now
      </ModalTitle>
      <ModalDescription>
        Subscribe now if you would like to hear more about us.
      </ModalDescription>
      <SubscriptionFormFields>
        <EmailField
          value={email}
          placeholder="Email"
          onChange={ev => setEmail(ev.target.value)}
        />
        <GetUpdatesButton />
      </SubscriptionFormFields>
      {status === 'sending' && <StatusMessage>Subscribing...</StatusMessage>}
      {status === 'error' && (
        <ErrorStatusMessage>
          { /already\ssubscribed/.test(message)
            ? 'You have already signed up.'
            : 'Failed to subscribe. Please try again!'
          }
        </ErrorStatusMessage>
      )}
    </StyledSubscriptionForm>
  );
};

SubscriptionForm.defaultProps = {
  status: null,
  message: null,
};

SubscriptionForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
};

const EnhancedSubscriptionForm = compose(
  withState('email', 'setEmail', ''),
  withHandlers({
    onSubmit: ({ subscribe, email }) => ev => {
      ev.preventDefault();
      if (email) {
        subscribe({ EMAIL: email });
      }
    },
  }),
)(SubscriptionForm);

EnhancedSubscriptionForm.propTypes = {
  subscribe: PropTypes.func.isRequired,
};

export { EnhancedSubscriptionForm as SubscriptionForm };
