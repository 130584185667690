import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTextArea = styled.textarea`
  width: 40rem;
  max-width: 100%;
  height: 8rem;
  padding: 1rem;
  box-sizing: border-box;
`;

const TextArea = ({
  placeholder,
  value,
  onChange,
}) => (
  <StyledTextArea
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

TextArea.defaultProps = {
  placeholder: null,
  value: null,
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export { TextArea };