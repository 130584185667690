import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLES } from '../../../style';
import { DropdownMenuItem } from './DropdownMenuItem';
import { DropdownItemProp } from './DropdownItemProp';

const StyledDropdownMenu = styled.div`
  position: absolute;
  background-color: ${STYLES.COLOR_WHITE};
  border: 1px solid ${STYLES.BORDER_COLOR};
`;

const DropdownMenu = ({ items, className, onItemClick }) => (
  <StyledDropdownMenu
    className={className}
  >
    { items.map((item, index) =>
      <DropdownMenuItem
        key={`${item.key}-${index}`}
        item={item}
        onItemClick={onItemClick}
      />
    )}
  </StyledDropdownMenu>
);

DropdownMenu.defaultProps = {
  className: null,
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(DropdownItemProp)).isRequired,
  className: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
}

export { DropdownMenu };