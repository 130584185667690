import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FaClose } from 'react-icons/lib/fa';
import { STYLES } from '../../../style';

const StyledModal = styled.div`
  background-color: rgba(0,0,0,.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100
`;

const ModalContent = styled.div`
  background-color: ${STYLES.COLOR_WHITE};
  padding: 1rem 2rem;
  position: relative;
  border-radius: 1rem;
  max-width: 80%;
`;

const ModalClose = styled(FaClose)`
  right: -1rem;
  top: -1rem;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  padding: .2rem;
  color: #fff;
  background-color: #333;
  border-radius: 2rem;
  cursor: pointer;
  &:hover {
    opacity: .9;
  }
`;

const Modal = ({ children, onCloseClick, className }) => (
  <StyledModal
    onClick={() => onCloseClick()}
  >
    <ModalContent
      className={className}
      onClick={ev => {
        ev.stopPropagation();
      }}
    >
      <ModalClose onClick={() => onCloseClick()} />
      { children }
    </ModalContent>
  </StyledModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export { Modal };