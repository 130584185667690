import PropTypes from 'prop-types';
import { LocationProp } from './RouteProp';

const UserProp = {
  uuid: PropTypes.string,
  avatarUrl: PropTypes.string,
  imageKeys: PropTypes.arrayOf(PropTypes.string),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  location: PropTypes.shape(LocationProp),
};

const MerchantProp = Object.assign(
  {
    businessName: PropTypes.string,
  }, UserProp,
);

export { UserProp, MerchantProp };
