import {
  compose, withStateHandlers, withState, withHandlers,
} from 'recompose';
import { FormHelper } from '../../../helpers/FormHelper';

const withFormFields = ({ initialValues, validationFields } = {}) => Component => compose(
  withState('formValidationMessages', 'setFormValidationMessages', {}),
  withStateHandlers(
    props => ({ formFields: initialValues ? initialValues(props) : {} }),
    {
      setFormField: ({ formFields }) => (fieldName, fieldValue) => ({
        formFields: {
          ...formFields,
          [fieldName]: fieldValue,
        },
      }),
      setFormFieldError: ({ formFields }) => error => ({
        formFields: {
          ...formFields,
          error,
        },
      }),
      setFormFieldLoading: ({ formFields }) => loading => ({
        formFields: {
          ...formFields,
          loading,
        },
      }),
      clearFormFields: props => () => ({ formFields: initialValues ? initialValues(props) : {} }),
    },
  ),
  withHandlers({
    formValidation: ({ formFields, setFormValidationMessages }) => () => {
      const results = FormHelper.isFormValid(
        formFields,
        validationFields,
      );
      setFormValidationMessages({});
      if (results.messages) {
        setFormValidationMessages(results.messages);
      }
      return results;
    },
  }),
)(Component);

export { withFormFields };
