import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Location } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import { compose, withState, lifecycle } from 'recompose';
import styled from 'styled-components';
import { Header } from '../Header';
import { ModalManager } from '../ModalManager';

import '../../normalize.css';
import './layout.css';
import { Footer } from '../Footer';
import { withScroll } from '../hoc/withScroll';
import { ScrollUpButton } from '../ScrollUpButton';
import { showModal } from '../../../states/modal/actions';
import { MODAL_TYPE } from '../ModalManager/constants';

const MetaTags = ({ data }) => {
  const { site: { siteMetadata: { title, description, keywords } } } = data;
  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
      ]}
    >
      <html lang="en" />
    </Helmet>
  );
};

MetaTags.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
};

const Content = styled.div`
  min-height: 70rem;
`;

const Layout = ({
  children,
  headerTransparent,
  isShowFreezedHeader,
  headerY,
}) => (
  <Location>
    {({ navigate, location }) => (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {
          const { pathname } = location;
          return (
            <>
              <MetaTags data={data} />
              <Header
                pathname={pathname}
                transparent={headerTransparent}
              />
              <Header
                pathname={pathname}
                freezed
                showFreezedHeader={isShowFreezedHeader}
                headerY={headerY}
              />
              <Content>
                <ModalManager />
                {typeof children === 'function' ? children({ navigate, location }) : children}
              </Content>
              <ScrollUpButton />
              <Footer />
            </>
          );
        }}
      />
    )}
  </Location>
);

Layout.defaultProps = {
  headerY: null,
  headerTransparent: false,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  headerTransparent: PropTypes.bool,
  isShowFreezedHeader: PropTypes.bool.isRequired,
  headerY: PropTypes.number,
};

const EnhancedLayout = compose(
  connect(),
  withState('isShowFreezedHeader', 'setIsShowFreezedHeader', false),
  withState('headerY', 'setHeaderY'),
  withScroll({
    callback: (ev, props) => {
      const { setIsShowFreezedHeader, setHeaderY } = props;
      const { scrollY } = window;
      const isHeaderFreezed = scrollY > 75;
      setIsShowFreezedHeader(isHeaderFreezed);
      setHeaderY(scrollY);
    },
  }),
  lifecycle({
    componentDidMount() {
      if (typeof window !== 'undefined') {
        const hasSubscriptionShownKey = 'hasSubscriptionShown';
        if (!window.localStorage.getItem(hasSubscriptionShownKey)) {
          if (window.location.pathname !== '/') {
            return;
          }
          const { dispatch } = this.props;
          dispatch(showModal(MODAL_TYPE.SUBSCRIPTION_MODAL));
          window.localStorage.setItem(hasSubscriptionShownKey, true);
        }
        if (!('ontouchstart' in document.documentElement)) {
          document.body.className += ' no-touch';
        }
      }
    },
  }),
)(Layout);

export { EnhancedLayout as Layout };
