import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HeaderLink } from './HeaderLink';
import { HeaderLinkProp } from './HeaderLinkProp';
import { SCREEN_SIZE } from '../../../style';

const StyledHeaderLinks = styled.div`
  display: none;
  ${SCREEN_SIZE.LG} {
    display: flex;
    flex: 1;
    margin-left: 1rem;
    justify-content: flex-end;
  }
`;

const HeaderLinks = ({
  links,
  linkColor,
  linkHoverColor,
  pathname,
  linkActiveColor,
}) => (
  <StyledHeaderLinks>
    { links.map(({ url, text, activeRe }) => (
      <HeaderLink
        color={linkColor}
        hoverColor={linkHoverColor}
        activeColor={linkActiveColor}
        key={url}
        url={url}
        active={activeRe ? activeRe.test(pathname) : pathname.replace(/\/$/, '') === url}
      >
        {text}
      </HeaderLink>
    ))}
  </StyledHeaderLinks>
);

HeaderLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(HeaderLinkProp)).isRequired,
  linkColor: PropTypes.string.isRequired,
  linkHoverColor: PropTypes.string.isRequired,
  linkActiveColor: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export { HeaderLinks };