import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLES } from '../../../style';

const StyledButton = styled.button`
  padding: 1rem;
  background-color: ${STYLES.COLOR_PRIMARY};
  color: ${STYLES.COLOR_WHITE};
  border: 0;
  border-radius: ${STYLES.BORDER_RADIUS};
  width: 100%;
  outline: 0;
  cursor: pointer;
  margin: 1rem 0;
  &:active {
    opacity: .9;
  }
  &:disabled {
    background-color: ${STYLES.COLOR_SILVER_GRAY};
    cursor: default;
  }
`;

const Button = ({
  children,
  onClick,
  className,
  disabled,
}) => (
  <StyledButton
    className={className}
    onClick={onClick}
    disabled={disabled}
  >
    { children }
  </StyledButton>
);

Button.defaultProps = {
  onClick: null,
  className: null,
  disabled: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export { Button };