import PropTypes from 'prop-types';
import { LocationProp } from './RouteProp';
import { UserProp } from './UserProp';

const DeliveryProp = {
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  parcelId: PropTypes.string,
  submittedAt: PropTypes.string,
  status: PropTypes.string,
  from: PropTypes.shape(LocationProp),
  to: PropTypes.shape(LocationProp),
  currency: PropTypes.string,
  price: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  weight: PropTypes.number,
  size: PropTypes.number,
  distance: PropTypes.number,
  //distanceFromCurrentLocation: PropTypes.number,
  carrier: PropTypes.shape(UserProp),
  isLast: PropTypes.bool,
};

export { DeliveryProp };
