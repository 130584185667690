import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../style';

const StyledMobileMenuButton = styled.div`
  width: 30px;
  height: 22.5px;
  display: inline-block;
  cursor: pointer;
  transition: .5s ease-in-out;
  transform: rotate(0);
  span {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 4.5px;
    border-radius: 9px;
    background: ${({ color }) => color};
    opacity: 1;
    transition: .25s ease-in-out;
    transform: rotate(0);
    &:nth-child(1) { top: 0; }
    &:nth-child(2), &:nth-child(3) { top: 9px; }
    &:nth-child(4) { top: 18px; }
    ${props => props.open && css`
      &:nth-child(1) {
        top: 18px;
        left: 50%;
        width: 0%;
      }
      &:nth-child(2) { transform: rotate(45deg); }
      &:nth-child(3) { transform: rotate(-45deg); }
      &:nth-child(4) {
        top: 18px;
        left: 50%;
        width: 0;
      }
      background: ${STYLES.COLOR_WHITE};
    `}
  }
  ${SCREEN_SIZE.LG} {
    display: none;
  }
`;

const MobileMenuButton = ({
  style,
  color,
  open,
  className,
  onClick,
}) => (
  <StyledMobileMenuButton
    style={style}
    color={color}
    open={open}
    className={className}
    onClick={onClick}
  >
    <span />
    <span />
    <span />
    <span />
  </StyledMobileMenuButton>
);

MobileMenuButton.defaultProps = {
  style: [],
  className: '',
};

MobileMenuButton.propTypes = {
  // eslint-disable-next-line
  style: PropTypes.array,
  color: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export { MobileMenuButton };