import PropTypes from 'prop-types';
import { UserProp } from './UserProp';

const MessageProp = {
  uuid: PropTypes.string,
  user: PropTypes.shape(UserProp),
  text: PropTypes.string,
  createdAt: PropTypes.string,
};

export { MessageProp };
