import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ScrollLock from 'react-scrolllock';
import { MobileMenuButton } from '../MobileMenuButton';
import { Link } from '../Link';
import { SCREEN_SIZE, STYLES } from '../../../style';
import { HeaderLinkProp } from '../Header/HeaderLinkProp';

const StyledMobileTopNav = styled.div`
  display: flex;
  align-items: center;
  ${SCREEN_SIZE.LG} {
    display: none;
  }
`;

const Overlay = styled.div`
  max-height: 0;
  height: 100%;
  transition: .3s ease-out max-height;
  z-index: 100;
  ${props => props.open && css`
    position: fixed;
    background-color: rgba(0,0,0,0.9);
    top: 0;
    right: 0;
    left: 0;
    max-height: 1000px;
  `}
  ${SCREEN_SIZE.LG} {
    display: none;
  }
`;

const StyledMobileMenuButton = styled(MobileMenuButton)`
  z-index: 1000;
  position: relative;
  margin: 1rem;
`;

const Menu = styled.div`
  padding-top: 5rem;
  height: 100%;
  overflow: auto;
`;

const MobileMenuItem = styled(Link)`
  margin: 3rem 0;
  display: block;
  text-align: center;
  color: ${STYLES.COLOR_WHITE};
  text-decoration: none;
  font-size: 1rem;
  ${props => props.active && css`
    color: ${STYLES.COLOR_PRIMARY};
  `}
  .no-touch &:hover {
    color: ${STYLES.COLOR_PRIMARY};
  }
`;

const MobileTopNav = ({
  transparent,
  isOpen,
  onOpenChange,
  links,
  pathname,
}) => (
  <StyledMobileTopNav>
    <StyledMobileMenuButton
      color={transparent ? STYLES.COLOR_WHITE : STYLES.LINK_COLOR}
      open={isOpen}
      onClick={() => onOpenChange(!isOpen)}
    />
    { typeof window !== 'undefined' && ReactDOM.createPortal(
      <>
        <Overlay open={isOpen}>
          { isOpen && (
            <Menu>
              <ScrollLock />
              { links && links.length > 1 && links.map(({ url, text, activeRe }) => (
                <MobileMenuItem
                  onClick={() => onOpenChange(false)}
                  key={url}
                  to={url}
                  active={activeRe ? activeRe.test(pathname) : pathname === url}
                >
                  {text}
                </MobileMenuItem>
              )) }
            </Menu>
          ) }
        </Overlay>
      </>,
      document.body,
    )}
  </StyledMobileTopNav>
);

MobileTopNav.propTypes = {
  transparent: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape(HeaderLinkProp)).isRequired,
  pathname: PropTypes.string.isRequired,
};

export { MobileTopNav };