import Big from 'big.js';
import { LocaleHelper } from './LocaleHelper';
import { SHIPMENT_STATUS } from '../constants/ShipmentStatus';

const ShipmentHelper = {};

const ACTIVE_STATUSES = [
  SHIPMENT_STATUS.COMMUTER_ACCEPTED,
  SHIPMENT_STATUS.COMMUTER_PICKED_UP,
  SHIPMENT_STATUS.RECIPIENT_CONFIRMED,
];
const HISTORY_STATUSES = [SHIPMENT_STATUS.DELIVERED, SHIPMENT_STATUS.MERCHANT_CANCELLED];

ShipmentHelper.groupShipmentsForList = shipments => {
  if (!shipments) {
    throw new Error('groupShipmentsForList(): shipments cannot be empty');
  }
  if (shipments.length === 0) { return shipments; }
  let results = [];
  results.push({ title: LocaleHelper.getText('GENERAL_ACTIVE') });
  results = results.concat(shipments.filter(shipment => ACTIVE_STATUSES.includes(shipment.status)));
  results[results.length - 1].isLast = true;
  results.push({ title: LocaleHelper.getText('GENERAL_HISTORY') });
  results =
    results.concat(shipments.filter(shipment => HISTORY_STATUSES.includes(shipment.status)));
  results[results.length - 1].isLast = true;
  return results;
};

ShipmentHelper.shipmentStatusToText = status => {
  if (!status) { return undefined; }
  return {
    [SHIPMENT_STATUS.COMMUTER_ACCEPTED]: LocaleHelper.getText('SHIPMENTS_ACCEPTED'),
    [SHIPMENT_STATUS.DELIVERED]: LocaleHelper.getText('SHIPMENTS_DELIVERED'),
    [SHIPMENT_STATUS.COMMUTER_PICKED_UP]: LocaleHelper.getText('SHIPMENTS_PICKED_UP'),
    [SHIPMENT_STATUS.MERCHANT_CANCELLED]: LocaleHelper.getText('SHIPMENTS_CANCELLED'),
    [SHIPMENT_STATUS.MERCHANT_SUBMITTED]: LocaleHelper.getText('SHIPMENTS_SUBMITTED'),
  }[status];
};

ShipmentHelper.getDisplayPrice = shipment => shipment.currency + shipment.price;
ShipmentHelper.getDisplayWeight = shipment => `${Big(shipment.weight).div(1000)}kg`;
ShipmentHelper.getDisplayDistance = shipment => `${Big(shipment.distance).div(1000)}km`;
ShipmentHelper.getDisplayDistanceFromCurrentLocation = shipment => `${shipment.distanceFromCurrentLocation}m`;
ShipmentHelper.getPostcode = (shipment, type = 'to') => {
  const { postcode } = shipment[type];
  const newPostcode = postcode.replace(/-/g, '');
  return `${newPostcode.substr(0, 3)}-${newPostcode.substr(3)}`;
};
ShipmentHelper.getFullAddress = (shipment, type = 'to') => {
  const location = shipment[type];
  const { building } = location;
  return `${building ? `${building} `: ''}${location.street}, ${location.city} ${ShipmentHelper.getPostcode(shipment)}, ${location.prefecture}`
};
ShipmentHelper.getToFullAddress = shipment => ShipmentHelper.getFullAddress(shipment);
ShipmentHelper.getFromFullAddress = shipment => ShipmentHelper.getFullAddress(shipment, 'from');
ShipmentHelper.getToPersonName = shipment => `${shipment.to.firstName} ${shipment.to.lastName}`;
ShipmentHelper.getShortAddress = (shipment, type = 'to') => {
  const location = shipment[type];
  return `${location.city}, ${location.prefecture}`;
};
ShipmentHelper.getFromShortAddress = shipment => ShipmentHelper.getShortAddress(shipment, 'from');
ShipmentHelper.getToShortAddress = shipment => ShipmentHelper.getShortAddress(shipment, 'to');

export { ShipmentHelper };
