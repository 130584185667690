import { CONFIG } from '../config';

const FileHelper = {};

FileHelper.getFileUrl = fileKey => {
  if (!fileKey) {
    return null;
  }
  return (
    `${CONFIG.STATIC_IMAGE_HOST}/${fileKey}`
  );
};


export { FileHelper };
