import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { SubscriptionForm } from './SubscriptionForm';
import { Modal } from '../Modal';
import { CONFIG } from '../../../config';

const StyledSubscriptionModal = styled(Modal)`
`;

const url = CONFIG.MAILCHIMP_URL;

const SubscriptionModal = ({
  onCloseClick,
}) => (
  <StyledSubscriptionModal
    onCloseClick={onCloseClick}
  >
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <SubscriptionForm
          status={status}
          message={message}
          subscribe={subscribe}
        />
      )}
    />
  </StyledSubscriptionModal>
);

SubscriptionModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
};

export { SubscriptionModal };