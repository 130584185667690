import { LocaleHelper } from './LocaleHelper';

const FormHelper = {};

FormHelper.isFormValid = (formFields, fields) => {
  const messages = {};
  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    const fieldName = currentField.name;
    const fieldValue = formFields[fieldName];
    if (!fieldValue) {
      messages[fieldName] = LocaleHelper.getText('GENERAL_REQUIRED_VALIDATION');
    }
    if (fieldValue && currentField.validate) {
      const validation = currentField.validate(fieldValue);
      if (validation.valid === false) {
        messages[fieldName] = validation.message;
      }
    }
  }

  if (Object.keys(messages).length > 0) {
    return { valid: false, messages };
  }
  return { valid: true };
};

FormHelper.phoneNumberValidation = text => {
  const checkText = text.replace(/(-|\s)/g, '');
  if (!/^0/.test(checkText)) {
    return { valid: false, message: LocaleHelper.getText('PROFILE_PHONE_START_WITH_ZERO') };
  }
  if (/^\d{10,11}$/.test(checkText)) {
    return { valid: true };
  }
  return { valid: false, message: LocaleHelper.getText('PROFILE_PHONE_VALIDATION_MESSAGE') };
};

FormHelper.postCodeValidation = text => {
  if (/^\d{3}-\d{4}$/.test(text.trim())) {
    return { valid: true };
  }
  return { valid: false, message: LocaleHelper.getText('PROFILE_POSTCODE_VALIDATION_MESSAGE') };
};

FormHelper.passwordValidation = text => {
  if (text.length < 8) {
    return ({ valid: false, message: LocaleHelper.getText('PROFILE_PASSWORD_POLICY') });
  }
  return { valid: true };
};

FormHelper.repeatPasswordValidation = (confirmedPassword, password) => {
  if (confirmedPassword !== password) {
    return ({ valid: false, message: LocaleHelper.getText('PROFILE_REPEAT_PASSWORD_VALIDATION') });
  }
  return ({ valid: true });
};

FormHelper.emailValidation = email => {
  if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)) {
    return ({ valid: false, message: LocaleHelper.getText('GENERAL_EMAIL_VALIDATION') });
  }
  return ({ valid: true });
};

export { FormHelper };
