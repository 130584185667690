const LocaleHelper = () => {};

const DEFAULT_LOCALE = 'en';

LocaleHelper.setLocale = l => {
  LocaleHelper.locale = l;
};

LocaleHelper.getLocale = () => LocaleHelper.locale || DEFAULT_LOCALE;

LocaleHelper.getText = key => key;


export { LocaleHelper };
