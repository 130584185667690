import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { SCREEN_SIZE, STYLES } from '../../../style';
import { Centralized } from '../layout';
import { HeaderLogo } from '../Header/HeaderLogo';
import { Link } from '../Link';
import FacebookImageSource from '../../../images/facebook.svg';
import TwitterImageSource from '../../../images/twitter.svg';
import InstagramImageSource from '../../../images/instagram.svg';
import MailImageSource from './images/mail.svg';
import SubscriptionImageSource from './images/subscription.png';
import { showModal } from '../../../states/modal/actions';
import { MODAL_TYPE } from '../ModalManager/constants';

const SocialLink = styled(Link)`
`;

const StyledFooter = styled.div`
  padding: 2.75rem 0 1rem;
  background-color: ${STYLES.SECTION_GRAY_BACKGROUND_COLOR};
  ${SCREEN_SIZE.LG} {
    padding: 2.75rem 0 1rem;
  }
`;

const FirstSection = styled.div`
  border-bottom: 1px solid ${STYLES.BORDER_COLOR};
  padding-bottom: 2rem;
  ${SCREEN_SIZE.LG} {
    display: flex;
  }
`;

const SecondSection = styled.div`
  margin: 1.5rem 0 1rem;
  text-align: center;
  ${SCREEN_SIZE.LG} {
    margin: 1rem 0;
    text-align: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
`;

const LogoSection = styled.div`
  text-align: center;
  flex: 1;
  ${SCREEN_SIZE.LG} {
    text-align: left;
  }
`;

const PagesSection = styled.div`
  margin-top: 2rem;
  text-align: center;
  ${SCREEN_SIZE.LG} {
    text-align: left;
    display: flex;
    flex: 1;
    margin-top: 0;
  }
`;

const PageSectionItem = styled.div`
  flex: 1;
`;

const PageSectionItemTitle = styled.div`
  margin-bottom: 1.6rem;
  margin-top: 2rem;
  font-weight: 600;
  font-size: ${STYLES.FONT_SIZE_MD};
  color: ${STYLES.COLOR_BLACK};
  ${SCREEN_SIZE.LG} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

const PageSectionItemLink = styled(Link)`
  display: block;
  color: ${STYLES.LINK_COLOR};
  margin-bottom: 1rem;
  .no-touch &:hover {
    text-decoration: none;
  }
  ${SCREEN_SIZE.LG} {
    margin-bottom: 0.5rem;
  }
`;

const CopyrightText = styled.div`
  line-height: ${STYLES.LINE_HEIGHT_MD};
`;

const SocialIconContainer = styled.div`
  margin-bottom: 1rem;
  white-space: nowrap;
  ${SCREEN_SIZE.LG} {
    margin-bottom: 0;
  }
`;

const SecondSectionLinkContainer = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
  white-space: nowrap;
  ${SCREEN_SIZE.LG} {
    margin-bottom: 0;
  }
`;

const SecondSectionLink = styled(Link)`
  padding: 0 2rem 0 0;
  color: ${STYLES.LINK_COLOR};
  &:last-of-type {
    padding: 0;
  }
  ${SCREEN_SIZE.LG} {
    padding: 0 2rem;
    text-decoration: none;
    ${({ noLeftPadding }) => noLeftPadding && `
      padding-left: 0;
    `}
  }
`;

const SocialImage = styled.img`
  padding: 0 .5rem;
  width: 1.5rem;
  height: 1.5rem;
  ${({ noRightPadding }) => noRightPadding && `
    padding-right: 0;
  `}
  .no-touch &:hover {
    ${({ unclickable }) => !unclickable && `
      opacity: 0.6;
    `}
  }
`;

const ClickableIconContainer = styled.div`
  text-align: center;
  margin: 2rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${STYLES.COLOR_PRIMARY};
  }
  ${SCREEN_SIZE.LG} {
    max-width: 15rem;
    margin: 1rem 2rem;
  }
`;

const MailIcon = styled.img.attrs({
  src: MailImageSource,
})`
  width: 4rem;
  height: 4rem;
`;

const SubscriptionIcon = styled.img.attrs({
  src: SubscriptionImageSource,
})`
  width: 4rem;
  height: 4rem;
`;

const MailText = styled.div`
  color: ${STYLES.TEXT_COLOR};
`;

const FakePageSectionItemLink = styled.div`
  color: ${STYLES.LINK_COLOR};
`;

const MailAndSubscriptionContainer = styled.div`
  ${SCREEN_SIZE.LG} {
    display: flex;
  }
`;

const Footer = ({
  dispatch,
}) => (
  <StyledFooter>
    <Centralized>
      <FirstSection>
        <LogoSection>
          <HeaderLogo />
          <MailAndSubscriptionContainer>
            <ClickableIconContainer
              onClick={() => dispatch(showModal(MODAL_TYPE.SUBSCRIPTION_MODAL))}
            >
              <SubscriptionIcon />
              <MailText>Subscribe</MailText>
            </ClickableIconContainer>
            <ClickableIconContainer>
              <Link to="mailto:support@handcarry.co.jp">
                <MailIcon />
                <MailText>Say Hello</MailText>
              </Link>
            </ClickableIconContainer>
          </MailAndSubscriptionContainer>
        </LogoSection>
        <PagesSection>
          <PageSectionItem>
            <PageSectionItemTitle>Company</PageSectionItemTitle>

            <PageSectionItemLink to="/vision">Our vision</PageSectionItemLink>
            <PageSectionItemLink to="/services">Services</PageSectionItemLink>
            <PageSectionItemLink to="/how-it-works">How it works</PageSectionItemLink>
            <PageSectionItemLink to="/legal">Legal</PageSectionItemLink>
            <PageSectionItemLink to="/faq">FAQ</PageSectionItemLink>
          </PageSectionItem>
          <PageSectionItem>
            <PageSectionItemTitle>Businesses</PageSectionItemTitle>

            <PageSectionItemLink to="/services">Services</PageSectionItemLink>
            <PageSectionItemLink to="/businesses/how-to">The flow</PageSectionItemLink>
            <PageSectionItemLink to="/businesses/security">Security</PageSectionItemLink>
            <PageSectionItemLink to="/businesses/support">Support</PageSectionItemLink>
            <PageSectionItemLink to="/businesses/savings">Savings</PageSectionItemLink>
          </PageSectionItem>
          <PageSectionItem>
            <PageSectionItemTitle>Commuters</PageSectionItemTitle>

            <PageSectionItemLink to="/commuters">Benefits</PageSectionItemLink>
            <FakePageSectionItemLink>Sign up</FakePageSectionItemLink>
          </PageSectionItem>
        </PagesSection>
      </FirstSection>
      <SecondSection>
        <SocialIconContainer>
          <SocialLink to="https://www.instagram.com/handcarry_jp"><SocialImage src={InstagramImageSource} /></SocialLink>
          <SocialImage unclickable src={FacebookImageSource} />
          <SocialLink to="https://twitter.com/handcarry_jp"><SocialImage noRightPadding src={TwitterImageSource} /></SocialLink>
        </SocialIconContainer>
        <SecondSectionLinkContainer>
          <SecondSectionLink noLeftPadding to="/legal/privacy-policy">Privacy Policy</SecondSectionLink>
          <SecondSectionLink to="/legal/terms-of-service">Terms of Service</SecondSectionLink>
        </SecondSectionLinkContainer>
        <CopyrightText>
          &copy; 2018-2021 Hand Carry International. All rights reserved. <br/>
          Designed by Tropical Thrive Limited
        </CopyrightText>
      </SecondSection>
    </Centralized>
  </StyledFooter>
);

Footer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const EnhancedFooter = compose(
  connect(),
)(Footer);

export { EnhancedFooter as Footer };
