import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { compose, withState } from 'recompose';
import OutsideClickHandler from 'react-outside-click-handler';
import { DropdownItemProp } from './DropdownItemProp';
import { DropdownMenu } from './DropdownMenu';
import { Text } from '../Text';
import { STYLES } from '../../../style';

const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid ${STYLES.BORDER_COLOR};
  margin: ${STYLES.GUTTER} 0;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  padding: 0 1rem;
  flex: 1;
  .no-touch &:hover {
    opacity: .9;
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  z-index: 1;
`;

const DropdownContent = styled.div`
  display: flex;
  padding: .5rem 1rem;
  align-items: center;
`;

const StyledFaAngleDown = styled(FaAngleDown)`
`;

const Dropdown = ({
  defaultText,
  icon,
  items,
  value,
  setIsShowMenu,
  isShowMenu,
  onItemClick,
}) => {
  const selectedItem = items
    .find(item => item.value === value);
  let Icon = icon;
  if (selectedItem && selectedItem.icon) {
    const StyledIcon = styled(selectedItem.icon)`
      max-width: 18px;
    `;
    Icon = <StyledIcon />;
  }
  return (
    <StyledDropdown>
      <OutsideClickHandler
        onOutsideClick={() => setIsShowMenu(false)}
      >
        <DropdownContent
          tabIndex={1}
          onClick={() => setIsShowMenu(!isShowMenu)}
        >
          { Icon && Icon }
          <StyledText>{ selectedItem ? selectedItem.text : defaultText }</StyledText>
          <StyledFaAngleDown />
        </DropdownContent>
        { isShowMenu
          && <StyledDropdownMenu
            items={items}
            onItemClick={item => {
              onItemClick(item);
              setIsShowMenu(false);
            }}
          />
        }
      </OutsideClickHandler>
    </StyledDropdown>
  );
};

Dropdown.defaultProps = {
  defaultText: null,
  icon: null,
  value: null,
};

Dropdown.propTypes = {
  defaultText: PropTypes.string,
  isShowMenu: PropTypes.bool.isRequired,
  setIsShowMenu: PropTypes.func.isRequired,
  icon: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.shape(DropdownItemProp)).isRequired,
  value: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
};

const EnhancedDropdown = compose(
  withState('isShowMenu', 'setIsShowMenu', false),
)(Dropdown);

export { EnhancedDropdown as Dropdown };