import PropTypes from 'prop-types';

const LocationProp = {
  building: PropTypes.string,
  branch: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  prefecture: PropTypes.string,
  street: PropTypes.string,
};

const RouteProp = {
  color: PropTypes.string,
  from: PropTypes.shape(LocationProp),
  to: PropTypes.shape(LocationProp),
  days: PropTypes.arrayOf(PropTypes.number),
};

export { RouteProp, LocationProp };
