const ScrollHelper = {};

ScrollHelper.scrollTo = (y, scrollDuration = 300) => {
  const scrollHeight = window.scrollY;
  const scrollStep = Math.PI / (scrollDuration / 15);
  const cosParameter = scrollHeight / 2;
  let scrollCount = 0;
  let scrollMargin;
  const scrollInterval = setInterval(() => {
    if (window.scrollY > y) {
      scrollCount += 1;
      scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
      window.scrollTo(y, (scrollHeight - scrollMargin));
    } else clearInterval(scrollInterval);
  }, 15);
};

ScrollHelper.scrollToTop = scrollDuration => {
  if (scrollDuration) {
    return ScrollHelper.scrollTo(0, scrollDuration);
  }
  return window.scrollTo(0, 0);
};

export {
  ScrollHelper as default,
  ScrollHelper,
};