import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLES } from '../../../style';

const StyledTextBox = styled.div`
  border: 1px solid ${STYLES.BORDER_COLOR};
  border-radius: ${STYLES.BORDER_RADIUS};
`;

const TextBoxInput = styled.input`
  padding: 1rem;
`;

const TextBox = ({
  type,
  value,
  className,
  onChange,
  placeholder,
}) => (
  <StyledTextBox className={className}>
    <TextBoxInput
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </StyledTextBox>
);

TextBox.defaultProps = {
  className: '',
  type: 'text',
  placeholder: '',
};

TextBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { TextBox };